import fs from 'fs'
import { render } from 'pug'

import dapps from './dapps.yaml';

const dappsContainer = document.querySelector('.world .dapps'),
      dappTemplate = fs.readFileSync(__dirname + '/_dapp.pug', 'utf8');

dapps.forEach((dapp) => {
  dappsContainer.insertAdjacentHTML('beforeend', render(dappTemplate, dapp))
})
