-
  name: "PulseLitecoin"
  description: "Dual mine PulseLitecoin and PulseBitcoin with ASIC token"
  isNew: true
  url: https://pulselitecoin.app/

-
  name: LP Vision
  description: "<small>(formerly Uniswap v3 Tools)</small><br>View liquidity for pairs & positions across multiple dexes"
  isNew: true
  url: https://lpvision.korkey.tech/

-
  name: PulseBitcoin Dashboard
  description: "Pulsebitcoin stats & alternative front-end. Also view future market supply & calculate mining profit over time."
  url: https://pulsebitcoin.korkey.tech/

-
  name: PulseDogecoin Carnival Attractions
  description: "Bulk manage & create PLSBLock NFTs"
  url: https://plsd-carnival.korkey.tech/

-
  name: PHUX Tools
  description: "Collection of tools to calculate IL & vePHUX boost for PHUX"
  url: https://phux-tools.korkey.tech/

